.container_alter_pass .container{
    width: 500px;
    height: 530px;
    background-color: white;
    border-radius: 7px;
    margin-top: 3.5%;
}

.container_alter_pass label{
    margin-top: 10px;
    font-size: 25px;
    font-weight: bold;
    color: black;
    border-bottom: 3px solid rgb(182, 182, 182);
    width: 480px;
}

.container_alter_pass .row{
    margin-top: 20px;
}

.container_alter_pass input{
    width: 340px;
    height: 40px;
    background-color: #EBECEF;
    border: white;
    border-radius: 2px;
    font-size: 20px;
    text-align: center;
}


.container_alter_pass input::placeholder{
    font-size: 20px;
}

.container_alter_pass .usuario{
    margin-top: 30px;
}

.container_alter_pass .novasenha{
    margin-top: 30px;
}

.container_alter_pass .btnsalvar{
    margin-top: 45px;
    width: 130px;
    height: 40px;
    background-color: black;
    border: none;
    border-radius: 5px;
    font-size: 20px;
    color: white;
}

.container_alter_pass .btnsalvar:hover{
    background-color: #545454;
}

.container_alter_pass .btnsalvar:active{
    background-color:#E4892D;
}