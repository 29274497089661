.estadocivilview #container{
    height: 713px;
    background-color: white;
    margin-left: 5%;
    margin-right: 6%;
}

.estadocivilview #colheader{
    background-color: #EBECEF;
}

.estadocivilview .btnorder{
    background-color: white;
    border:none
}

.estadocivilview .inputfiltro{
    font-size: 15px;
    width: 80%;
    border:none;
    border-radius: 3px;
    text-align: center;
}

.estadocivilview .inputfiltro::placeholder{
    font-weight: bold;
    font-size:18px
}

.estadocivilview .select-grupo-empresa{
    width: 130px;
    height: 30px;
    background-color: white;
    font-size: 18px;
    padding-left: 20px;
    font-weight: bold;
    border-radius: 5px;
    border:white
}

.estadocivilview .searchinput{
    width: 200px;
    height: 30px;
    font-size: 18px;
    text-align: center;
    color: #575756;

    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath d='M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z'/%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: 18px 18px;
    background-position: 95% center;
    border-radius: 5px;
    border: none
}

.estadocivilview .btnbuscar{
    background-color: black;
    color: white;
    font-size: 13px;
    border-radius: 8px;
    margin-left: 3%;
}

.estadocivilview .btnbuscar:hover{
    background: #545454;
}

.estadocivilview .btnbuscar:active{
    background: #E4892D;
}

.estadocivilview #rowtable{
    border-top: 10px solid #EBECEF ;
}

.estadocivilview .labelheader{
    font-weight: bold;
}

.estadocivilview table{
    border-collapse: collapse;
    text-align: center;
    width: 100%;
    min-height:620px;
    max-height:620px;
}

.estadocivilview th {
    border-bottom: 4px solid #dddddd;
    height: 40px;
}

.estadocivilview .thborder{
    border-left: 4px solid #dddddd;
    border-right: 4px solid #dddddd;
}

.estadocivilview td{
    border: 2px solid #dddddd;
    white-space: nowrap;
}

.estadocivilview #rowfooter{
    height: 50px;
}

.estadocivilview .open-modal-add{
    border: none;
    background-color: white;
}

.estadocivilview .labeladd{
    cursor: pointer;
}

.estadocivilview .labeladd:hover{
    color: #545454;
}

.estadocivilview .labeladd:active{
    color:#E4892D;
}

.estadocivilview .img-add{
    margin-bottom: 4%;
}

.estadocivilview .btnpages{
    border: none;
    background-color: white;
    margin-bottom: 1%;
}

.estadocivilview #pagenation{
    border-left: 11px solid white;
    border-right: 2px solid white;
}

.estadocivilview .btnheadertable{
    background-color: white;
    cursor: pointer;
    border: none;
    width: 100%;
}

.estadocivilview .btnheadertable:hover{
    font-weight: bold;
    cursor: pointer;
}

.estadocivilview .btnheadertable:active{
    font-weight: bold;
    color: #E4892D;
    cursor: pointer;
}

.estadocivilview .lbheadertable{
    cursor: pointer;
}

.estadocivilview .inputtable{
    font-size: 15px;
    width: 100%;
    border:none;
    text-align: center;
}