.vendas-tabela #container{
    height: 100%;
    margin-left: 5%;
    margin-right:5%;
    background-color: white;
}



.vendas-tabela  #rowheader{
    background-color: #EBECEF;
    height: 50px;
    text-align: left;
}

.vendas-tabela #colheader{
    border-radius: 8px;
    border: 2px solid #c2c2c2;
}

.vendas-tabela input::placeholder{
    text-align: center;
    font-size: 12px;
}

.vendas-tabela input{
    font-size: 12px;
    text-align: center;
    height: 30px;
    border-radius: 8px;
    border: none;
    margin-left: 1%;
    width:15%;
}

.vendas-tabela select{
    font-size: 14px;
    text-align: center;
    height: 30px;
    overflow: hidden;
    border-radius: 8px;
    border: none;
    margin-left: 1%;
    width:22%;
}

.vendas-tabela select {
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
}


.vendas-tabela input[type="date"]::-webkit-calendar-picker-indicator,
.vendas-tabela input[type="date"]::-webkit-inner-spin-button{
    display: none;
}

.vendas-tabela .inputdata{
    width: 70%;
    height: 20px;
}  

.vendas-tabela #rowlabels{
    height:20px;
    text-align: center;
}

.vendas-tabela .labeldateini{
    font-size: 12px;
}

.vendas-tabela .labeldatefim{
    font-size: 12px;
}

.vendas-tabela .nome_vendedor{
    width: 80%;
    font-size: 14px;
}

.vendas-tabela .contrato{
    width: 70%;
    font-size: 14px;
}

.vendas-tabela .unidade{
    width: 65%;
    font-size: 14px;
}

.vendas-tabela .sala{
    width: 55%;
    font-size: 14px;
}

.vendas-tabela .empreend{
    width: 75%;
    font-size: 14px;
}

.vendas-tabela .btnbuscar {
    border: none;
    width: 100%;
    margin-bottom: 1%;
    background-color: white;
}

.vendas-tabela .btndtsub{
    width: 70%;
    border: none;
    background-color: white;
}

.vendas-tabela .btndtsub:hover{
    cursor: pointer;
}

.vendas-tabela .labeldtsubbtn{
    cursor: pointer;
}

.vendas-tabela .btnlimpar {
    font-size: 15px;
    background-color: white;
    border: none;
    width: 100%;
}


.vendas-tabela #rowlimpar{
    float: right;
}

.vendas-tabela .thdt{
    width: 120px;
}

.vendas-tabela .thstatus{
    width: 120px;
}

.vendas-tabela .thcliente{
    width: 210px;
}

.vendas-tabela .thlocalizador{
    width: 115px;
}

.vendas-tabela .thempreend{
    width: 150px;
}

.vendas-tabela .thsala{
    width: 80px;
}

.vendas-tabela .thunid{
    width: 100px;
}

.vendas-tabela .thunid{
    width: 100px;
}

.vendas-tabela .thcontrato{
    width: 130px;
}

.vendas-tabela .thvendedor{
    width: 200px;
}

.vendas-tabela .thdet{
    width: 50px;
}

.vendas-tabela table{
    border-collapse: collapse;
    text-align: center;
    width: 100%;
    min-height:720px;
    max-height:720px;
}

.vendas-tabela #rowfooter{
    height: 50px;
}

.vendas-tabela th {
    border: 2px solid #dddddd;
    border-bottom: 4px solid #dddddd;
    font-size: 11px;
}

.vendas-tabela td{
    border: 2px solid #dddddd;
    font-size: 11px;
    white-space: nowrap;
}

.vendas-tabela .inputtd{
    font-size: 12px;
    width: 98%;
}

.vendas-tabela .inputth{
    font-size: 11px;
    float: right;
    width: 70%;
}

.vendas-tabela .btnth{
    float:left;
    margin-top:5px;
    border:none;
    background-color:white
}

.vendas-tabela .labelth{
    margin-top: 5px;
}

.vendas-tabela .btnpage{
    border: none;
    background-color: white;
    margin-bottom: 1%;
}

.vendas-tabela .btnedit{
    background-color: white;
    border: none;
}

.vendas-tabela .btnimg{
    background-color: white;
    border: none;
}

.vendas-tabela .btnimg:hover{
    opacity: 0.2;
}

.vendas-tabela .btnimg:active{
    opacity: 0.5;
}

.vendas-tabela #rowinputheader{
    justify-content: center;
}

.vendas-tabela #qtdade{
    background-color: #EBECEF;
    font-size: 10px;
    text-align: center;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}