.modal-vendas .container{
    position: absolute;
    display: block;
    width: 130%;
    height: 90%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 25px;
    border: none;
    background: white;
    overflow:auto;
}

.modal-vendas #rowheader{
    justify-content: center;
    text-align: center;
    height: 15%;
    border-bottom: 3px solid #EBECEF;
}

.modal-vendas #rowbody{
    height: 85%;
}

.modal-vendas .col{
    height: 100%;
}

.modal-vendas #rowcondpag{
    height: 50%;
    justify-content: center;
}

.modal-vendas #rowvendedor{
    height: 50%;
    justify-content: center;
}

.modal-vendas .labelmsgstatus{
    font-size: 14px;
}

.modal-vendas .labelstatus{
    font-size: 22px;
    font-weight: bold;
    font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}

.modal-vendas #coltables{
    border-right: 3px solid #EBECEF;
}

.modal-vendas .table{
    width: 100%;
}

.modal-vendas #rowlabel{
    margin-top: 1.3%;
    font-size: 14px;
}

.modal-vendas .inputvalue{
    border: none;
    width: 100%;
}

.modal-vendas table{
    width: 85%;
    height: 80px;
    text-align: center;
}

.modal-vendas th{
    font-size: 14px;
    border:2px solid #EBECEF;
    background-color: #c5c5c5;
}

.modal-vendas td{
    font-size: 13px;
    border:2px solid #EBECEF;
}

.modal-vendas .labelheader{
    width: 89%;
    text-align: center;
    margin-top: 1.2%;
    font-size: 15px;
    background-color: #c5c5c5;
    border-top:2px solid #EBECEF;
    border-left:2px solid #EBECEF;
    border-right:2px solid #EBECEF;
    font-weight: bold;
}

.modal-vendas #colheader{
    text-align: center;
}

.modal-vendas a {
    margin-top: 10%;
    margin-right: 9%;
    width: 50px;
    height: 50px;
}

.modal-vendas a:hover{
    cursor:Pointer;
}

.modal-vendas .imgjson:hover{
    opacity: 0.3;
}

.modal-vendas .imgjson:active{
    opacity: 0.5;
}

.modal-vendas .btnclose{
    border: none;
    background-color: white;
    float: right;
}

.modal-vendas .btnclose:active{
    color: #E4892D;
}

.modal-vendas .inputfiltro{
    font-size: 12px;
    width: 80%;
    border:none;
    border-radius: 3px;
    text-align: center;
}