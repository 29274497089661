.admin-dashboard #containertotal{
    margin-top: 1%;
    height: 800px;
    color: rgb(59, 59, 59);
    margin-left: 5%;
    margin-right: 5%;
}

.admin-dashboard #rowvendashoje{
    width: 100%;
    height: 180px;
    background-color: white;
    border-left: 5px solid blue;
    border-top: 1px solid rgb(207, 207, 207);
    border-right: 1px solid rgb(207, 207, 207);
    border-bottom: 1px solid rgb(207, 207, 207);
    border-radius: 7px;
    justify-content: center;
}

.admin-dashboard #rowerros{
    width: 100%;
    height: 180px;
    background-color: white;
    border-left: 5px solid rgb(185, 39, 39);
    border-top: 1px solid rgb(207, 207, 207);
    border-right: 1px solid rgb(207, 207, 207);
    border-bottom: 1px solid rgb(207, 207, 207);
    border-radius: 7px;
    justify-content: center;
}

.admin-dashboard .labelvendashoje{
    justify-content: center;
    width: 100%;
    height: 30%;
    font-weight: bold;
    align-items: center;
    margin-top: 3%;
}

.admin-dashboard #colvendashoje{
    height: 67%;
}

.admin-dashboard #colvendasrecent{
    width: 75%;
    height: 420px;
    background-color: white;
    justify-content: center;
    border-radius: 7px;
    border-top: 3px solid blue;
}

.admin-dashboard table{
    margin-top: 3%;
    border-collapse: collapse;
    text-align: center;
    width: 100%;
    min-height:270px;
    max-height:270px;
}

.admin-dashboard th{
    background-color: #dddddd;
    border-bottom: 2px solid rgb(80, 80, 80);
    height: 40px;
}

.admin-dashboard td{
    border-bottom: 2px solid #dddddd;
    white-space: nowrap;
}

.admin-dashboard .btngotablevendas{
    background-color: white;
    border:none
}

.admin-dashboard .lbgotablevendas{
    cursor: pointer;
    font-weight: bold;
}

.admin-dashboard .lbgotablevendas:hover{
    color: #545454;
}

.admin-dashboard .lbgotablevendas:active{
    color: #E4892D;
}

.admin-dashboard #rowgraficos{
    margin-top: 2.4%;
    background-color: white;
    width: 100%;
    height: 215px;
    border-radius: 5px;
    border-top: 3px solid blue;
}

.admin-dashboard #colgraficosstatus{
    margin-top: auto;
    margin-bottom: auto;
}

.admin-dashboard #rowgrafics{
    height: 250px;
    margin-top: 1.5%;
    background-color: white;
    border-top: 3px solid blue;
}

.admin-dashboard .inputtable{
    font-size: 15px;
    width: 98%;
    border:none;
    text-align: center;
}