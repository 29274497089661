.login .container{
    display: block;
}

.login .row {
    margin-top: 4%;
    text-align: center;
}

.login input{
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    width: 21%;
    background-color: #EBECEF;
    font-size: 19px;
}

.login input::placeholder{
    font-size: 17px;
}

.login .form-group{
    margin-top: 1%;
}

.login .btn{
    margin-top: 1%;
    background-color: black;
    color: white;
    font-size: 18px;
    border-radius: 8px;
}

.login .btn:hover{
    background: #545454;
}

.login .btn:active{
    background: #E4892D;
    border:none;
}

.login .footer{
    position: fixed;
    display: block;
    left: 0;
    margin-left: 87%;
    bottom: 3%;
    width: 10%;
    color: black;
    text-align: right;
    float: left;
}