.navbar-admin .row{
    background-color: white;
    border-bottom: 3px solid rgb(89, 109, 223);
    height: 60px;
    align-items: center;
    margin: 0;
    padding: 0;
}

.navbar-admin #icon{
    text-align: left;
}


.navbar-admin #colvendas{
    text-align: left;
    margin-left: 2%;
}

.navbar-admin #cssmenu > ul > li {
    display:inline-block;
    position: relative;
}

.navbar-admin #cssmenu > ul {
    text-align:center;
}

.navbar-admin ul{
    list-style-type: none;
}

.navbar-admin .lidropdown{
    color: black;
    padding: 8px 15px;
    text-decoration: none;
    display: block;
    align-content: center;
}

.navbar-admin #cssmenu > ul > li > ul {
    opacity: 0;
    visibility: hidden;
    padding: 16px 0 20px 0;
    background-color: rgb(246, 246, 246);
    position: absolute;
    text-align: center;
    left: 50%;
    margin-left: -90px;
    width: 180px;
}

.navbar-admin #cssmenu > ul > li:hover > ul {
    opacity: 1;
    visibility: visible;
}

.navbar-admin .has-sub{
    white-space: nowrap;
}

.navbar-admin .has-sub:hover{
    font-weight: bold;
    cursor: pointer;
}

.navbar-admin #user{
    text-align: right;
    float: right;
}

.navbar-admin .drop_exit_change{
    border: none;
    width: 50px;
    margin-right: 10%;
    background-color: white;
    text-align: center;
}



.navbar-admin #rowdropdown2{
    text-align: center;
}

.navbar-admin .dropdown2{
    display: inline-block;
}

.navbar-admin .dropdown-content2 {
    display: none;
    position: absolute;
    background-color: rgb(246, 246, 246);
    min-width: 173px;
    max-width: 173px;
    padding: 13px 17px;
    right: 0;
}

.navbar-admin .dropdown-content2 a {
    color: black;
    padding: 8px 15px;
    text-decoration: none;
    display: block;
    align-content: center;
}

.navbar-admin a {
    color: black;
    text-decoration: none;
}

.navbar-admin .dropdown2:hover .dropdown-content2 {
    display: block;
}

.navbar-admin a:hover {
    font-weight: bold;
    cursor: pointer; 
}

.navbar-admin a:active {
    color: orange;
}

.navbar-admin .usuario{
    font-size: 12px;
}

.navbar-admin .labelnome{
    font-size: 12px;
    margin-right: 5px;
}

