#modal_ecivil{
    position: absolute;
    display: block;
    width: 850px;
    height: 250px;
    border-radius: 25px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: none;
    background: white;
    outline: none;
}

#modal_ecivil .labelheader{
    font-size: 15px;
    text-align: center;
}


#modal_ecivil .labeltitle{
    font-weight: bold;
}

#modal_ecivil input{
    width: 100%;
    font-size: 13px;
    border-radius: 5px;
    border:1px solid black
}

#modal_ecivil input[type="number"]::-webkit-outer-spin-button,
#modal_ecivil input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

#modal_ecivil input[type="number"] {
    -moz-appearance: textfield;
}

#modal_ecivil .btnatrelar{
    background-color: black;
    color: white;
    font-size: 19px;
    width: 100px;
    height: 45px;
    border:none;
    border-radius: 8px;
    margin-right: 5px;
    margin-left: 10px;
}

#modal_ecivil .btnatrelar:hover{
    background: #545454;
}

#modal_ecivil .btnatrelar:active{
    background: #E4892D;
}

#modal_ecivil .btnlimpar{
    background-color: white;
    border:none;
    border-radius: 8px;
    margin-left: 5px;
}

#modal_ecivil img:hover{
    opacity: 0.2;
}

#modal_ecivil img:active{
    opacity: 0.5;
}